<template>
  <App title="充电查询"
       left-arrow
       class="chargingSearch">
    <div slot="extra">
      <Search v-model="value"
              placeholder="请输入小区名称"
              shape="round"
              @search="onSearch" />
    </div>
    <div id="content"
         v-show="showResult">
      <List v-model="loading"
            :finished="finished"
            :finished-text="total === 0 ?'暂无数据' : 'No more items.'"
            @load="onLoad">
        <CellGroup v-for="$i in radioList"
                   :key="$i.label">
          <Cell clickable>
            <template #title>
              <div v-html="$i.houseName"
                   class="custom-title"></div>
            </template>
            <template #right-icon>
              <!-- <Radio :name="$i.houseNum" /> -->
              <Icon :name="$i.isPermit ? 'passed' : 'close'"
                    :color="$i.isPermit ? '#089D06' : '#C8C8C8'"
                    size="20"></Icon>
            </template>
          </Cell>
        </CellGroup>
      </List>
    </div>
  </App>
</template>

<script>
import { Search, CellGroup, Cell, Toast, List, Icon } from 'vant';
import { getChargeSearch } from '@/api/chargequery'
import 'vant/lib/nav-bar/style'
import 'vant/lib/search/style'
import 'vant/lib/radio-group/style'
import 'vant/lib/cell-group/style'
import 'vant/lib/cell/style'
import 'vant/lib/radio/style'
import '@/style/vant-patch.less'
import './index.less'
export default {
  name: 'chargingSearch',
  data () {
    return {
      value: '',
      showResult: false,
      list: [],
      loading: false,
      radioList: [],
      finished: false,
      total: 0,
      current: 1,
    }
  },
  components: {
    Search,
    CellGroup,
    Cell,
    List,
    Icon
  },
  methods: {
    onLoad () {
      if (this.radioList.length === this.total) {
        this.finished = true
        return
      }
      this.current++
      this.getData()
    },
    init () {
      this.current = 1
      this.list = []
    },
    async getData () {
      Toast.loading({
        message: 'Loading...',
        forbidClick: true,
        loadingType: 'spinner',
        overlay: true
      })
      const res = await getChargeSearch({
        page: this.current,
        size: 20
      }, { name: this.value })
      Toast.clear()
      if (res.success) {
        const name = this.value
        const reg = new RegExp(name, 'g')
        res.data.records.forEach(item => {
          item.houseName = item.houseName.replace(reg, `<span class="color-weight">${this.value}</span>`)
        })
        this.radioList = [...this.radioList, ...res.data.records]
        this.loading = false
        this.total = res.data.total
      } else {
        this.loading = false
        Toast(res.msg)
      }
    },
    onSearch () {
      this.radioList = []
      this.total = 0
      this.showResult = true
      this.getData()
    }
  },
  mounted () {
    this.init()
  }
}
</script>